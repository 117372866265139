<template>
<div id="page-descripciones">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Descripciones para Artículos" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nueva" }} Descripción</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Descripción" v-model="model.descripcion" 
                @input="upperCaseInput('descripcion')"
                :rules="[rules.required]"
                v-on:keydown.enter.prevent='1'></v-text-field>
              </v-col>
            </v-row>            

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.catalogos.marcas')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    
  },
  data() {
    return {
      model_filters: "",
      valida_nombre: false,
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      name: "datagrid",
      columns: [
        "descripcion",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: ["descripcion"],
        sortable: ["descripcion"],

        headings: {
          descripcion: "Descripción",
          actions: "Operaciones"
        },
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catálogos",
          disabled: true,
          href: ""
        },
        {
          text: "Descripciones",
          href: ""
        }
      ],

      modal: false,

      update: false,

      model: {
        _id: "",
        _rev: "",
        type: "descripciones",
        descripcion: "",        
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"descripciones"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,                        
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    openModal: function(type) {
      if (type === 1) {
        this.update = false;
        this.model._id = "";
        this.model.descripcion = "";
      } else {
        this.update = true;
      }

      this.modal = true;
    },

    validaUnicos: async function(){
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
          await self.validaDescripcion().then(result => {
          }).catch(err => {
            duplicados.push("Descripcion");
          });

          if(duplicados.length > 0)
            return reject(duplicados);
          else
            return resolve(true);

      });

    },

    validaDescripcion: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.descripcion != null && self.model.descripcion!="") {
            var busca = encodeURI(escape(self.model.descripcion.toString().trim().toLowerCase()));
            window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/descripciones/_view/descripcion?key=\"'+busca+'\"')
              .then(response => {

                  if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                    if(self.model._id != ""){//Esta editando
                      var filter = response.data.rows.find( r => {
                          return self.model._id != r.id;
                      });

                      if(filter){
                        return reject(false);
                      }
                    } else { //Es nuevo
                        return reject(false);
                    }
                  }
                  return resolve(true);
              })
              .catch(err => {
                  return reject(false);
              });
          } else
            return resolve(true);
      });
    },

    getRegistro: function(id_registro) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          this.model = response.data;
          this.openModal(2);
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    saveRegistro: function() {

      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
         this.validaUnicos().then( result => {

            let data = {
              type: this.model.type,
              descripcion: this.model.descripcion != null ? this.model.descripcion.toString().trim() : "",              
            };

            window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
              .then(response => {
                this.modal = false;
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                  color: 'success'
                });
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
              })
              .catch(error => {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al guardar el registro.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
              });
        }).catch( err => {

            window.dialogLoader.hide();
            var mensaje = "";
            if(err.length > 1)
              mensaje = err.join(', ')+" ya existen. Favor de verificar.";
            else
              mensaje = err.join(', ')+" ya existe. Favor de verificar.";

            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: mensaje,
              footer: ""
            });
        });

      }
    },
    updateRegistro: function() {

      if (this.$refs.form.validate()) {
        
        window.dialogLoader.show('Espere un momento por favor..');
          this.validaUnicos().then( result => {

            var model_old = "";
            window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model._id)
              .then(responseOld => {
                model_old = responseOld.data;
                
                let data = this.model;
                window.axios
                  .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                  .then(response => {

                      if (model_old['descripcion'] != this.model['descripcion']){
                        var datos = {
                          'campo': 'descripcion',
                          'oldvalue': model_old['descripcion'],
                          'newvalue': this.model['descripcion'],
                        };
                        window.funciones.updateArticulos(datos)
                          .then(result => {
                            console.log("Actualización correcta");
                          }).catch(error => {
                            console.log("Error al actualizar articulos: ",error);                              
                          });
                      } 
                      window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                        color: 'success'
                      });
                      this.registros.items = [];
                      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');                                        
                      
                    
                  })
                  .catch(error => {
                    this.modal = false;
                    window.dialogLoader.hide();
                    this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al actualizar el registro. Intente Nuevamente.",
                      footer: ""
                    });                    
                  }).then(() => {
                    this.modal = false;
                    window.dialogLoader.hide();
                  });
              })
              .catch(error => {
                this.modal = false;
                window.dialogLoader.hide();
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener el registro. Intente Nuevamente.",
                  footer: ""
                });
              });

            

        }).catch( err => {
              window.dialogLoader.hide();
              var mensaje = "";
              if(err.length > 1)
                mensaje = err.join(', ')+" ya existen. Favor de verificar.";
              else
                mensaje = err.join(', ')+" ya existe. Favor de verificar.";

              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: mensaje,
                footer: ""
              });
          });
      }
    },
    
    deleteRegistro: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.registros.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
            }).then(()=>{
              window.dialogLoader.hide();
            });

        }

      });
    },

  }
};
</script>
